import { executeApi, useApi } from "../../composables/useApi";
import { computed, ref } from "vue";
import { createSharedComposable } from "@vueuse/core";

interface MyScore {
  exist: boolean;
  gpa: number | null;
  sat: {
    math: number | null;
    reading: number | null;
  };
  act: {
    english: number | null;
    math: number | null;
    reading: number | null;
    science: number | null;
  };
}

export default createSharedComposable(() => {
  const api = useApi();

  const myCollegeList = ref([]);
  const computedMyCollegeList = computed(() => {
    return myCollegeList.value.map((like) => {
      return {
        ...like,
        firstChance: calcMyAcceptance(
          storedMyScore.value,
          like.collegeNicheIpeds
        ),
        nowChance: calcMyAcceptance(myScore.value, like.collegeNicheIpeds),
      };
    });
  });

  const storedMyScore = ref<MyScore>({
    exist: false,
    gpa: null,
    sat: {
      math: null,
      reading: null,
    },
    act: {
      english: null,
      math: null,
      reading: null,
      science: null,
    },
  });
  const myScore = ref<MyScore>({
    exist: true,
    gpa: 0,
    sat: {
      math: 200,
      reading: 200,
    },
    act: {
      english: 1,
      math: 1,
      reading: 1,
      science: 1,
    },
  });
  const isNotSetMyScore = computed(() => {
    const { gpa, sat, act } = myScore.value;
    return (
      !gpa &&
      !sat.math &&
      !sat.reading &&
      !act.english &&
      !act.math &&
      !act.reading &&
      !act.science
    );
  });

  const handleAddMyCollege = (ipedsCollegeId) => {
    executeApi(async () => {
      await api.college.saveMemberCollegeLike({
        reqCollegeLike: {
          ipedsCollegeId,
        },
      });
      fetchMyCollegeList();
    });
  };

  const handleDeleteMyCollege = (ipedsCollegeId) => {
    executeApi(async () => {
      await api.college.deleteMemberCollegeLike({
        ipedsCollegeId,
      });
      fetchMyCollegeList();
    });
  };

  const handleChangeAdmission = (ipedsCollegeId, round, status) => {
    executeApi(async () => {
      await api.college.addMemberCollegeAdmission({
        reqMemberCollegeAdmission: {
          ipedsCollegeId,
          round,
          status,
        },
      });
      fetchMyCollegeList();
    });
  };

  const fetchMyCollegeList = () => {
    executeApi(async () => {
      const { data } = await api.college.getMemberCollegeLikeList();
      myCollegeList.value = [
        ...data.map((like) => ({
          ...like,
          admissionRound: like.collegeNicheIpeds.admissionRound || "Interested",
          admissionStatus:
            like.collegeNicheIpeds.admissionStatus || "Not Yet Applied",
        })),
      ];
    });
  };

  const handleSaveMajor = (ipedsCollegeId, majorId) => {
    executeApi(async () => {
      await api.college.saveMemberCollegeLike({
        reqCollegeLike: { ipedsCollegeId, onetMajorId: majorId },
      });
      fetchMyCollegeList();
    });
  };

  const functionalFetchMyCollegeList = async () => {
    try {
      const { data } = await api.college.getMemberCollegeLikeList();
      return data.map((like) => ({
        ...like,
        admissionRound: like.collegeNicheIpeds.admissionRound || "Interested",
        admissionStatus:
          like.collegeNicheIpeds.admissionStatus || "Not Yet Applied",
      }));
    } catch (error) {
      console.error("Error fetching college list:", error);
      return []; // or handle the error as you see fit
    }
  };

  const fetchStoredMyScore = () => {
    executeApi(async () => {
      const { data } = await api.roadmap.getRoadmapInfo();
      const score: MyScore = {
        exist: data.exist,
        gpa: data.targetGpa && Number(data.targetGpa),
        sat: {
          math: data.satMath,
          reading: data.satReadingWriting,
        },
        act: {
          english: data.actEnglish,
          math: data.actMath,
          reading: data.actReading,
          science: data.actScience,
        },
      };

      storedMyScore.value = structuredClone(score);
      myScore.value = structuredClone(score);
    });
  };

  const asyncfetchStoredMyScore = async () => {
    await executeApi(async () => {
      const { data } = await api.roadmap.getRoadmapInfo();
      const score: MyScore = {
        exist: data.exist,
        gpa: data.targetGpa && Number(data.targetGpa),
        sat: {
          math: data.satMath,
          reading: data.satReadingWriting,
        },
        act: {
          english: data.actEnglish,
          math: data.actMath,
          reading: data.actReading,
          science: data.actScience,
        },
      };

      storedMyScore.value = structuredClone(score);
      myScore.value = structuredClone(score);
    });
  };

  const handleApplyMyScores = () => {
    fetchStoredMyScore();
  };

  const calcAdmissionRoundOption = (like) => {
    const {
      collegeNicheIpeds: {
        offersEarlyDecision,
        offersEarlyAction,
        offersRegularDecision,
        offersRolling,
      },
    } = like;

    const option = ["Interested"];

    if (offersEarlyDecision) {
      option.push("Early Decision");
    }
    if (offersEarlyAction) {
      option.push("Early Action");
    }
    if (offersRegularDecision) {
      option.push("Regular Decision");
    }
    if (offersRolling) {
      option.push("Rolling Admissions");
    }

    return option;
  };

  const calcAdmissionStatusOption = (admissionRound) => {
    if (
      admissionRound === "Early Action" ||
      admissionRound === "Early Decision"
    ) {
      return [
        "Not Yet Applied",
        "Applied",
        "Waitlisted",
        "Admitted",
        "Deferred",
        "Denied",
        "Enrolled",
      ];
    }

    return [
      "Not Yet Applied",
      "Applied",
      "Waitlisted",
      "Accepted",
      "Denied",
      "Enrolled",
    ];
  };

  function removeLeadingZeros(inputString) {
    return inputString.replace(/^0+/, ''); // Use a regular expression to replace leading zeros with an empty string
  }
  

  const convertDeadLineDate = (dateString) => {
    try {
      if (dateString === "Rolling Admissions") {
        return "Rolling Admissions";
      }
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let [year, monthWithSuffix, day] = dateString.split("-");
      let month = parseInt(monthWithSuffix.replace("월", "")) - 1;

      return `${months[month]} ${removeLeadingZeros(day)}`;
    } catch (e) {
      return "-";
    }
  };

  const calcDeadline = (like) => {
    const {
      admissionRound,
      collegeNicheIpeds: {
        applicationDeadline,
        earlyActionDeadline,
        earlyDecisionDeadline,
      },
    } = like;

    switch (admissionRound) {
      case "Interested":
        return "-";
      case "Early Decision":
        return convertDeadLineDate(earlyDecisionDeadline) || "-";
      case "Early Action":
        return convertDeadLineDate(earlyActionDeadline) || "-";
      case "Regular Decision":
        return convertDeadLineDate(applicationDeadline) || "-";
      case "Rolling Admissions":
        return convertDeadLineDate(applicationDeadline) || "-";
    }
  };

  const calcMyAcceptance = (score: MyScore, college) => {
    const {
      acceptRate: acceptRateFloat,
      collegeGpaData: {
        gpa: colGpa,
        satCompRange25: colSat25,
        satCompRange75: colSat75,
        actc25: colAct25,
        actc75: colAct75,
      },
    } = college;
    const { userGpa, userSat, userAct } = {
      userGpa: score.gpa || 2.5,
      userSat: (score.sat.math || 0) + (score.sat.reading || 0) || 1180,
      userAct:
        ((score.act.english || 0) +
          (score.act.math || 0) +
          (score.act.reading || 0) +
          (score.act.science || 0)) /
        4,
    };

    const tierRank = getTierBy(acceptRateFloat);

    const myRate = calcAccRate({
      tierRank,
      acceptRateFloat,
      colGpa,
      colSat25,
      colSat75,
      colAct25,
      colAct75,
      userGpa,
      userSat,
      userAct,
    });

    return Math.round(myRate * 100);
  };

  return {
    myCollegeList,
    computedMyCollegeList,
    storedMyScore,
    myScore,
    isNotSetMyScore,
    handleSaveMajor,
    asyncfetchStoredMyScore,
    calcAdmissionRoundOption,
    calcAdmissionStatusOption,
    calcMyAcceptance,
    convertDeadLineDate,
    calcDeadline,
    handleAddMyCollege,
    functionalFetchMyCollegeList,
    handleDeleteMyCollege,
    handleChangeAdmission,
    handleApplyMyScores,
    fetchMyCollegeList,
    fetchStoredMyScore,
  };
});

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// 레티튜가 제공한 계산식임. 각 상수들이 뭔지는 모름
const getTierBy = (acceptanceRate) => {
  if (acceptanceRate > 0.01 && acceptanceRate <= 0.1) return 1;
  if (acceptanceRate > 0.1 && acceptanceRate <= 0.2) return 2;
  if (acceptanceRate > 0.2 && acceptanceRate <= 0.35) return 3;
  if (acceptanceRate > 0.35 && acceptanceRate <= 0.5) return 4;
  if (acceptanceRate > 0.5 && acceptanceRate <= 0.75) return 5;
  if (acceptanceRate > 0.75) return 6;
  return 0;
};

const calcAccRate = ({
  userGpa,
  userSat,
  userAct,
  tierRank,
  colGpa,
  colSat25,
  colSat75,
  colAct25,
  colAct75,
  acceptRateFloat,
}) => {
  let calcTemp;

  if (userGpa && userSat) {
    calcTemp =
      calcGpa(userGpa, tierRank, colGpa) +
      calcSat(userSat, tierRank, colSat25, colSat75);
  } else if (!userSat && userAct) {
    calcTemp =
      calcGpa(userGpa, tierRank, colGpa) +
      calcAct(userAct, tierRank, colAct25, colAct75);
  } else if (!userSat && !userAct) {
    calcTemp = calcGpa(userGpa, tierRank, colGpa) * 2;
  }

  let multipliers = [10, 6, 5, 4, 2, 1.5];
  let additions = [0, 3, 5, 8, 10, 25];
  let caps = [0.4, 0.7, 0.85, 0.9, 0.98, 0.99];

  let accRateCalc =
    ((calcTemp + additions[tierRank - 1]) *
      multipliers[tierRank - 1] *
      acceptRateFloat) /
    100;
  accRateCalc =
    accRateCalc > caps[tierRank - 1] ? caps[tierRank - 1] : accRateCalc;

  accRateCalc = accRateCalc < 0 ? 0 : accRateCalc;
  accRateCalc = accRateCalc > 0.99 ? 0.99 : accRateCalc;

  return accRateCalc;
};

const calcGpa = (userGpa, tierRank, colGpa) => {
  let gpaCalc;
  let gpaLimit;
  let upperGpaWeight;
  let lowerGpaWeight;

  if (tierRank === 1 || tierRank === 2) {
    gpaLimit = 20;
    upperGpaWeight = 0.1;
    lowerGpaWeight = 0.1;
  } else if (tierRank === 3) {
    gpaLimit = 20;
    upperGpaWeight = 0.1;
    lowerGpaWeight = 0.15;
  } else if (tierRank === 4) {
    gpaLimit = 40;
    upperGpaWeight = 0.05;
    lowerGpaWeight = 0.2;
  } else if (tierRank === 5) {
    gpaLimit = 60;
    upperGpaWeight = 0.4;
    lowerGpaWeight = 0.3;
  } else if (tierRank === 6) {
    gpaLimit = 70;
    upperGpaWeight = 0.5;
    lowerGpaWeight = 0.35;
  }

  if (userGpa >= colGpa) {
    gpaCalc = 15 + ((userGpa - colGpa) * 1) / upperGpaWeight;
    if (gpaCalc > gpaLimit) {
      gpaCalc = gpaLimit;
    }
  } else {
    gpaCalc = 15 - ((colGpa - userGpa) * 2) / lowerGpaWeight;
  }

  if (gpaCalc < 0) {
    gpaCalc = 0;
  }

  return gpaCalc;
};

const calcAct = (userAct, tierRank, colAct25, colAct75) => {
  let actCalc;

  if (userAct >= colAct25) {
    let factor = [2, 3, 3.5, 4, 4.5, 5.5][tierRank - 1];
    let cap = [25, 25, 30, 40, 60, 70][tierRank - 1];
    actCalc = 15 + (userAct - colAct25) * factor;
    actCalc = actCalc > cap ? cap : actCalc;
  } else if (userAct <= colAct75) {
    let factor = [3, 2.5, 2, 1.5, 1, 0.5][tierRank - 1];
    actCalc = 15 - (colAct75 - userAct) * factor;
  } else {
    actCalc = 15;
  }

  actCalc = actCalc < 0 ? 0 : actCalc;

  return actCalc;
};

const calcSat = (userSat, tierRank, colSat25, colSat75) => {
  let multipliers = [2, 3, 3, 3, 4, 4.5];
  let dividers = [10, 10, 10, 10, 20, 30];
  let caps = [25, 25, 30, 40, 60, 70];

  let satCalc;

  if (userSat >= colSat25) {
    satCalc =
      15 +
      ((userSat - colSat25) * multipliers[tierRank - 1]) /
        dividers[tierRank - 1];
    satCalc = satCalc > caps[tierRank - 1] ? caps[tierRank - 1] : satCalc;
  } else if (userSat <= colSat75) {
    satCalc =
      15 -
      ((colSat75 - userSat) * multipliers[tierRank - 1]) /
        dividers[tierRank - 1];
  } else {
    satCalc = 15;
  }

  satCalc = satCalc < 0 ? 0 : satCalc;

  return satCalc;
};
